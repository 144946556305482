/* Add this to your existing CSS or create a new Skills.css file */

.skill-item {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 8px 12px;
    border-radius: 6px;
    transition: background-color 0.2s ease;
  }
  
  .skill-item:hover {
    background-color: rgba(38, 62, 217, 0.1);
  }
  
  .cert-item {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 8px 12px;
    border-radius: 6px;
    transition: background-color 0.2s ease;
  }
  
  .cert-item:hover {
    background-color: rgba(38, 62, 217, 0.1);
    color: #263ed9;
  }
  
  .items {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 20px;
    row-gap: 15px;
  }
  
  .items2 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 10px;
    row-gap: 15px;
  }
  
  /* Media queries for responsiveness */
  @media (max-width: 768px) {
    .items {
      grid-template-columns: repeat(2, 1fr);
    }
    
    .items2 {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  
  @media (min-width: 769px) and (max-width: 1024px) {
    .items {
      grid-template-columns: repeat(3, 1fr);
    }
  }


  .aws-certification-highlight {
    margin: 30px 0;
    display: flex;
    justify-content: center;
  }
  
  .aws-cert-link {
    text-decoration: none;
    display: block;
    transition: transform 0.3s ease;
    width: 90%;
    max-width: 800px;
  }
  
  .aws-cert-link:hover {
    transform: translateY(-5px);
  }
  
  .aws-cert-container {
    display: flex;
    align-items: center;
    background: linear-gradient(to right, #f5f5f5, white);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
    border-left: 5px solid #FF9900;
  }
  
  .aws-cert-container:hover {
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
  }
  
  .aws-cert-logo {
    margin-right: 20px;
    flex-shrink: 0;
  }
  
  .aws-cert-badge-img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  
  .aws-cert-details {
    flex: 1;
  }
  
  .aws-cert-details h4 {
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: 600;
    color: #232f3e;
  }
  
  .aws-cert-details p {
    font-size: 16px;
    color: #3f3f3f;
    line-height: 1.4;
  }
  
  .aws-cert-item {
    background-color: rgba(255, 153, 0, 0.1);
    border: 1px solid rgba(255, 153, 0, 0.3);
    font-weight: 600;
    position: relative;
    overflow: hidden;
  }
  
  .aws-cert-item::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background-color: #FF9900;
  }
  
  .aws-cert-item:hover {
    background-color: rgba(255, 153, 0, 0.2);
  }
  
  @media (max-width: 768px) {
    .aws-cert-container {
      flex-direction: column;
      text-align: center;
      padding: 25px 15px;
      border-left: none;
      border-top: 5px solid #FF9900;
    }
    
    .aws-cert-logo {
      margin-right: 0;
      margin-bottom: 15px;
    }
    
    .aws-cert-badge-img {
      width: 80px;
      height: 80px;
    }
    
    .aws-cert-details h4 {
      font-size: 18px;
    }
    
    .aws-cert-details p {
      font-size: 14px;
    }
  }