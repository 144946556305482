@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;700&family=Rubik:wght@400;500&display=swap");
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Rubik", sans-serif;
    color: #2b2b2b;
    text-decoration: none;
  }
  html{
    scroll-behavior: smooth;

  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes upOp {
    from {
      transform: translateY(-10%);
      opacity: 0.2;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes show {
    from {
      
      opacity: 0.2;
    }
    to {
      opacity: 1;
    }
  }

  // loading animation
  .loading {
    background-color: #e0e0db;
    height: 100vh;
    width: 100%;
    .center {
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      display: flex;
      img{
        width: 200px;
        height: auto;
        padding-bottom: 5px;
        animation:show 1s linear infinite alternate;
      }
    
    }
  }
  .dflex {
    display: flex;
    justify-content: space-between;
  }
  // Container
  .container {
    max-width: 70%;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .firstback {
    background-color: #e0e0db;
    height: 100vh;
    width: 100%;
    background-image: url("../images/backhero.svg");
    background-position: top right;
    background-repeat: no-repeat;
    background-size: 75%;
  }
  //***nav***
  nav {
    animation: fadeIn 0.5s ease-out;
    .backContainer {
      position: fixed;
      z-index: 100;
      top: 0;
      right: 0;
      width: 100%;
      height: 100px;
      padding: 0 5%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: transform 0.3s ease-in-out;
      transform: translateY(0%);
      
      .menu {
        font-size: 16px;
        a {
          padding-right: 50px;
          font-size: 14px;
          letter-spacing: 1px;
          color: #2b2b2b;
          text-transform: uppercase;
          &:hover {
            color: #263ed9;
          }
        }
        .resumebtn {
          border: 1px solid #263ed9;
          padding: 10px 25px;
          border-radius: 5px;
          font-size: 13px;
          font-weight: 500;
          font-size: 14px;
          font-weight: 500;
          text-transform: uppercase;
          background-color: #263ed9;
          border: solid 2px #263ed9;
          color: white;
          &:hover {
            background-color: #e0e0db;
            color: #263ed9;
          }
        }
      }
    }
    .humicon {
      display: none;
    }
  }
  .logo {
    padding-left:30px;
    img{
    width:120px;
  }}
  // scrolling nav
  .scrollTrue {
    height: 70px !important;
    background-color: rgba(224, 224, 219, 0.85);
    transform: translateY(0%) !important;
  }
  .scrollFalse {
    transform: translateY(-100%) !important;
  }
  //header
  .header {
    height: 100vh;
    display: flex;
    align-items: center;
    animation: upOp 1s;
    .content {
      p:first-child {
        color: #263ed9;
        font-size: 20px;
      }
      h1 {
        margin-top: 20px;
        font-size: 70px;
        margin-bottom: 10px;
      }
      h2 {
        font-size: 55px;
        color: #3f3f3f;
      }
      p {
        font-size: 20px;
        width: 73%;
        margin-top: 20px;
        line-height: 30px;
      }
      button {
        font-size: 16px;
        margin-top: 50px;
        padding: 15px 30px;
        background-color: #263ed9;
        border: solid 2px #263ed9;
        color: white;
        border-radius: 5px;
        &:hover {
          background-color: #e0e0db;
          color: #263ed9;
        }
      }
    }
  }
  //Socials
  .Social {
    .contactbtnFix {
      position: fixed;
      z-index: 20;
      right: 3%;
      bottom: 5%;
      &:hover {
        bottom: calc(5% + 3px);
      }
      button {
        animation: upOp 1s;
        border: transparent;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        align-items: center;
        background: linear-gradient(130deg, #263ed9, rgb(0, 204, 255));
        box-shadow: rgba(0, 77, 255, 0.5) 0px 4px 24px;
        width: 60px;
        height: 60px;
        img {
          width: 35px;
          height: auto;
        }
        &:hover {
          bottom: 6%;
          background: linear-gradient(130deg, #263ed9, rgb(81, 220, 255));
        }
      }
    }
  }
  .about {
    padding:100px 0 0 0;
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    max-width: 85%;
    .leftSide {
      position: relative;
      h4 {
        font-size: 35px;
        line-height: 57px;
        margin-bottom: 55px;
        img {
          margin-bottom: -10px;
        }
        span {
          display: block;
          &:nth-of-type(2) {
            margin-left: 10%;
          }
          &:nth-of-type(3) {
            margin-left: 30%;
          }
        }
      }
      p {
        font-size: 14px;
        color: #263ed9;
        margin: 10px 0;
        letter-spacing: 1px;
        margin-left: 5px;
      }
      .iconSocail {
        position: absolute;
        bottom: 0;
        width: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
        a {
          margin: 0 10px;
          padding: 13px;
          img {
            width: 30px;
            filter: invert(15%) sepia(3%) saturate(24%) hue-rotate(354deg) brightness(70%) contrast(94%);
          }
          &:hover {
            margin-top: -5px;
            filter: invert(20%) sepia(65%) saturate(4256%) hue-rotate(230deg) brightness(89%) contrast(91%);
          }
        }
      }
    }
    .rigthSide {
      padding-top: 50px;
      p {
        font-size: 18px;
        line-height: 28px;
      }
      .focus {
        margin: 16px 0 24px 0;
        font-weight: 700;
        color: #2b2b2b;
      }
      .rigthBoxs {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        span {
          color: #263ed9;
          font-size: 14px;
          text-transform: uppercase;
        }
        p {
          font-size: 18px;
          font-weight: 500;
          margin-top: 3px;
        }
      }
    }
  }
  .sectionTitle {
    font-size: 22px;
    text-transform: uppercase;
    color: #3f3f3f;
    font-weight: 500;
    letter-spacing: 1px;
    padding-bottom:25px;
    position: relative;
    &::after {
      content: "";
      width: 50px;
      height: 4px;
      background-color: #263ed9;
      position: absolute;
      bottom: 5px;
      left: 0px;
    }
  }
  .Projects {
    padding-top: 200px;
    .ProContainer {
      h3 {
        margin-top: 0px;
        margin-bottom: 25px;
      }
      max-width: 85%;
      margin: auto;
    }
  }
  .Project {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 10px;
    align-items: center;
    margin-bottom: 100px;
    &:last-of-type{
      margin-bottom: 0px;
    }
    &:nth-of-type(even) {
      .ProDetails {
        grid-column: 7/-1;
        grid-area: 1/7/-1/-1;
        text-align: right;
      }
      .ProjDemo {
        grid-column: 1/8;
        grid-area: 1/1/-1/8;
      }
    }
    .ProDetails {
      grid-column: 1/7;
      grid-area: 1/1/-1/7;
      z-index: 10;
      h4 {
        font-size: 24px;
        color: #2b2b2b;
        font-weight: 500;
        padding: 15px 0;
        font-weight:700;
        
      }
      .description {
        font-size: 18px;
        line-height: 28px;
        padding: 20px;
        background-color: #e0e0db;
        margin: 20px 0;
        border-radius: 5px;
        font-weight: 500;
        color: #2b2b2b;
      }
      .tags {
        font-size: 16px;
        color: #3f3f3f;
        font-weight: 500;
        word-spacing: 10px;
        margin-bottom: 25px;
        line-height: 26px;
        font-weight: 700;
        
      }
      a {
        &:first-of-type{
          padding-right: 20px;
        }
        
        &:hover{
          filter: invert(20%) sepia(65%) saturate(4256%) hue-rotate(230deg) brightness(89%) contrast(91%);;
        }
      }
    }
    .ProjDemo {
      grid-column: 6/-1;
      grid-area: 1/6/-1/-1;
      position: relative;
      &:hover {
        .linkIcon {
          display: block;
        }
        .imageLink img {
          filter: grayscale(100%) contrast(1) brightness(90%);
        }
      }
      .imageLink img {
        width: 100%;
      }
      .linkIcon {
        display: none;
        img {
          width: 40px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(3deg)
            brightness(100%) contrast(104%);
        }
      }
    }
  }
  //Conatact
  .contact{
    padding-top: 200px;
    padding-bottom: 100px;
    max-width: 85%;
    h3{
      margin-bottom: 25px;
    }
    .conatactTypes{
      display: grid;
      grid-template-columns: 1fr 0.5fr;
      h4{
        font-size: 40px;
        color: #3f3f3f;
        margin-bottom: 10px;
      }
      .form{
        margin-top: 50px;
        border-right: 2px solid #3f3f3f3d;
        
        input{
          padding:25px 0;
          margin-bottom: 30px;
          border: none;
          border-bottom: 2px solid #3f3f3f94;
          font-size: 18px;
          outline: none;
          color: #2b2b2b;
        }
        .name{
          width: 42.5%;
          margin-right: 5%;
        }
        .email{
          width: 42.5%;
        }
        .subject{
          display:block;
          width: 90%;

        }
        .message{
          width: 90%;
          border: none;
          border-bottom: 1px solid #2b2b2b;
          font-size: 18px;
          outline: none;
          padding-bottom: 80px;
          margin-bottom: 35px;
          resize: vertical;
        }
        .erorr{
          border-bottom: 2px solid #c94451 !important;
        }
        button{
        font-size: 16px;
        padding: 15px 30px;
        background-color: #263ed9;
        border: solid 2px #263ed9;
        color: white;
        border-radius: 5px;
        &:hover {
          background-color: white;
          color: #263ed9;
        }
        }
      }
    }
    .otherContact{
      width: 90%;
      margin: auto;
      padding-left: 20px;
      padding-top: 100px;
      align-items: center;
      h5{
        font-size: 25px;
        margin-bottom: 30px;
      }
      p{
        display: flex;
        align-items: center;
        color: #2b2b2b;
        font-weight: 500;
        margin-bottom: 30px;
        &:last-of-type(){
          letter-spacing: 2px;
        }
        img{
          margin-right: 15px;
        }
      }
      .mediatitle{
        margin-bottom: 25px;
      }
      .socilaMedia{
        display: flex;
        width: 70%;
        text-align: center;
        justify-content: space-between;
        img {
          width: 30px;
          filter: invert(15%) sepia(3%) saturate(24%) hue-rotate(354deg) brightness(70%) contrast(94%);
        }
        a:hover{
          margin-top:-3px;
          filter: invert(20%) sepia(65%) saturate(4256%) hue-rotate(230deg) brightness(89%) contrast(91%);;


        }
      }
    }
  }
  footer{
    background-color: #e0e0db;
    margin-top: 100px;
    padding:30px 9%;
    padding-bottom: 15px;
    h4{
      text-align: center;
      margin-top: 5px;
      font-size: 14px;
      font-weight: 500;
    }
    .footerbar{
      display: flex;
      justify-content: space-between;
      justify-items: center;
      h1{
        font-size:25px;
      }
      .iconSocail{
        a {
          margin:0;
          padding: 15px;
          img {
            width: 23px;
            filter: invert(15%) sepia(3%) saturate(24%) hue-rotate(354deg) brightness(70%) contrast(94%);
          }
          &:hover {
            margin-top: -5px;
            filter: invert(20%) sepia(65%) saturate(4256%) hue-rotate(230deg) brightness(89%) contrast(91%);
          }
        }
      }
    }
  }
  //skills
  .skills{
    max-width: 85%;
    margin: auto;
    padding-top: 150px;
    .slider{
      display: flex;
      padding-top: 35px;
      max-width: 80%;
      margin: auto;
      .sliderControl{
        button{
          display: block;
          width: 180px;
          padding: 25px;
          border: none;
          font-size: 16px;
          text-align: left;
          background: none;
          font-weight: 500;
          border-left: 3px solid #2b2b2b6f;
          letter-spacing: 1px;
          outline: none;
          
          &:hover{
            background-color: #e0e0db;
            color: #263ed9;
          }
        }
        .active{
          border-left: 4px solid #263ed9;
          color: #263ed9;
          font-weight:700;
          background-color: #e0e0db;
        }

      }
      .content{
        padding-left:50px;
        width: 75%;
        h4{
          font-size: 22px;
          font-weight: 600;
          letter-spacing: 1px;
          margin: 20px 0;
          color: #2b2b2b;
          &:last-of-type{
            margin-top: 35px;
          }
        }
        .items{
          display: grid;
          grid-template-columns: repeat(4,1fr);
          column-gap: 20px;
          row-gap: 15px;
          span{
              text-align: center;
            }
          span,a{
            display: flex;
            justify-items: center;
            align-items: center;
            font-size: 18px;
            padding: 5px 0;
            
            img{
              padding-right: 10px;
            }
          }
          a:hover{
            background-color: #e0e0db;
            border-radius: 5px;
          }
        }
        .items2{
          @extend .items;
          grid-template-columns: repeat(3,1fr);
          row-gap: 5px;
          column-gap: 10px;
          a{
            font-size: 16px;
          }
        }

      }
    }
  }
  @keyframes send {
    from{
      transform: scaleX(1);
    }
    to{
      transform: scaleX(0);
    }
  }
  @keyframes shownotif {
    from{
      transform: translateX(200%);
    }
    to{
      transform: translateX(0%);
    }
  }
  .notif{
    animation: shownotif 1s;
    position: fixed;
    transform: translateX(0%);
    z-index: 100;
    font-weight: 500;
    font-size: 16px;
    right: 1em;
    top: 1em;
    background-color: white;
    width:250px;
    border-radius: 4px;
    box-shadow: 0 1px 10px 0 rgba(0,0,0,.1), 0 2px 15px 0 rgba(0,0,0,.05);
    p{
      display: flex;
      padding:15px 10px;
      align-items: center;
      img{
        margin-right: 10px;
      }
    }
   .barnoti{
      width: 100%;
      height: 5px;
      background-color: #07bc0c;
      transform-origin: left;
      animation: send 5s;
      animation-delay: 1s;
      border-radius:0 4px 4px 0;
    }
  }