.experience {
    padding-top: 150px;
    max-width: 85%;
  }
  
  .experienceContainer {
    display: flex;
    flex-direction: column;
    gap: 60px;
    margin-top: 30px;
    width: 85%;
    margin: auto;
    padding-top: 50px;
  }
  
  .experienceItem {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    padding-left: 30px;
  }
  
  .experienceItem::before {
    content: "";
    position: absolute;
    left: 0;
    top: 8px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #263ed9;
  }
  
  .experienceItem::after {
    content: "";
    position: absolute;
    left: 5px;
    top: 25px;
    width: 2px;
    height: calc(100% - 20px);
    background-color: #263ed9;
    opacity: 0.3;
  }
  
  .experienceItem:last-child::after {
    display: none;
  }
  
  .experienceHeader {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    gap: 15px;
  }
  
  .experienceHeader h4 {
    font-size: 24px;
    font-weight: 700;
    color: #2b2b2b;
    margin-right: 10px;
  }
  
  .experienceHeader .company {
    font-weight: 500;
    font-size: 18px;
    color: #263ed9;
  }
  
  .experienceHeader .date {
    margin-left: auto;
    font-size: 16px;
    color: #3f3f3f;
    font-weight: 500;
  }
  
  .experienceContent {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  
  .project {
    background-color: #e0e0db;
    padding: 25px;
    border-radius: 8px;
    position: relative;
    transition: transform 0.3s ease;
  }
  
  .project:hover {
    transform: translateY(-5px);
  }
  
  .project h5 {
    font-size: 18px;
    font-weight: 600;
    color: #263ed9;
    margin-bottom: 15px;
  }
  
  .project p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .project ul {
    margin-left: 20px;
    margin-bottom: 20px;
  }
  
  .project li {
    margin-bottom: 8px;
    line-height: 1.5;
  }
  
  .techStack {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 15px;
  }
  
  .techStack span {
    font-size: 14px;
    background-color: rgba(38, 62, 217, 0.1);
    color: #263ed9;
    padding: 5px 12px;
    border-radius: 4px;
    font-weight: 500;
  }
  
  /* Media Queries for Responsive Design */
  @media (max-width: 768px) {
    .experience {
      padding-top: 75px;
      max-width: 90%;
      margin: auto;
    }
    
    .experienceHeader {
      flex-direction: column;
      gap: 5px;
    }
    
    .experienceHeader .date {
      margin-left: 0;
    }
    
    .project {
      padding: 20px;
    }
    
    .techStack {
      gap: 8px;
    }
    
    .techStack span {
      font-size: 12px;
      padding: 4px 8px;
    }
  }
  
  @media (min-width: 769px) and (max-width: 1024px) {
    .experience {
      padding-top: 100px;
    }
  }