@media (max-width: 768px) {
  .container {
    width: initial;
    max-width: initial;
  }
  html {
    overflow-x: hidden;
  }
  @keyframes menueffect {
    from {
      height: 0;
    }
    to {
      height: 100vh;
    }
  }
  .loading {
    .center{
      img{
        width:150px;
      }
    }
  }
  .firstback {
    background-size: 130% !important;
  }
  .dflex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 25px 35px 25px;
    z-index: 0;
  }
  .backmeu {
    max-width: 100% !important;
    background-color: #e0e0db;
    opacity: 0.96;
    height: 100vh;
    position: fixed;
    width: 100vw;
    left: 0;
    top: 0;
    margin: 0 !important;
    z-index: 10;
    animation: menueffect 0.6s;
    scroll-behavior: smooth;
  }
  .scrolN {
    overflow: hidden;
    height: 100vh;
  }
  nav {
    animation: none !important;
    .backContainer {
      display: block !important;
      padding: 30px 0 !important;
      .logo{
        padding-left: 0;
        img{
          height: 35px;
        }
      }
    }
    .humicon {
      display: block !important;
      width: 20px;
      cursor: pointer;

      img {
        filter: brightness(0);
      }
    }
    .menu {
      display: grid;
      text-align: center;
      padding: 0 25px;
      a {
        color: white;
        font-size: 18px;
        padding: 25px 0 !important;
        letter-spacing: 4px;
        border-top: 1px solid black;
        &:nth-last-child(2) {
          border-bottom: 1px solid black;
        }
      }
      .resumebtn {
        margin-top: 30px;
        padding: 15px 0 !important;
        font-size: 18px !important;
      }
    }
    .dismenu {
      display: none;
    }
  }
  .space {
    margin-top: 0vh !important;
  }
  .header {
    max-width: initial;
    padding: 0 9%;
    .content {
      h1 {
        margin-top: 15px !important;
        font-size: clamp(35px, 7vw, 80px) !important;
      }
      h2 {
        font-size: clamp(30px, 7vw, 60px) !important;
      }
      p {
        font-size: 16px !important;
        width: 100% !important;
        line-height: 20px;
      }
    }
  }
  .sectionTitle{
    font-size: 18px;
  }
  .Social {
    .contactbtnFix {
      button {
        width: 55px !important;
        height: 55px !important;
        img {
          width: 25px !important;
        }
      }
    }
  }
  .about {
    padding: 0 9%;
    margin-top: 75px;
    display: block !important;
    .leftSide {
      h4 {
        display: none !important;
      }
      .iconSocail {
        display: none !important;
      }
    }
    .rigthSide {
      margin-top: 25px;
      padding-top: 0;
      .rigthBoxs {
        display: block !important;
        span {
          font-size: 16px !important;
        }
        p {
          font-size: clamp(14px, 5vw, 20px);
          margin-top: 10px !important;
          margin-bottom: 20px !important;
        }
      }
    }
  }
.Projects{
  padding-top: 75px ;
}
  .Project {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 50px !important;
    background-color: #e0e0db;
    border-radius: 10px;
    position: relative;
    .ProDetails {
      text-align: left !important;
      padding: 0 25px 25px 25px;
      h4 {
        font-size: clamp(24px, 5vw, 28px) !important;
        padding-bottom: 0;
      }
      .description {
        padding: 0px;
        background: none;
        border-radius: 0px;
        font-size: 14px;
        line-height: 24px;
      }
      .tags {
        font-size: 14px;
      }
      a {
        padding-right: 30px;
      }
    }
  }
  //contact
  .contact {
    max-width: 90%;
    margin: auto;
    padding-top: 75px;
    .conatactTypes {
      display: initial;
    
      h4 {
        font-size: clamp(20%, 7vw, 45px);
      }
      .form {
        border-right: none !important;
        .name{
          width: 45%;
          margin-right: 10%;
        }
        .email{
          width: 45%;
        }
        .subject{
          width: 100%;

        }
        .message{
          width: 100%;
        }
      }
      .otherContact {
        width: 100%;
        padding: 50px 0;
        p {
          font-size: clamp(14px, 5vw, 20px);
          margin-right: 5px;
          img{
            margin-right:10px;
          }
        }
        .socilaMedia {
          display: none;
        }
        .mediatitle{
          display: none;
        }
      }
    }
  }
  //footer
  footer{
    margin-top:0px;
    text-align: center;
    .footerbar{
      display: initial;
      .iconSocail{
        margin-top: 20px;
        a {
          margin:0;
          padding: 20px;
        }
      }
    }
    h4{
      font-size: 16px;
    }
  }
  .skills{ 
    padding-top: 75px;
    .slider{
    max-width: 100%;
    display: block;
    padding-top: 0;
    .sliderControl{
      display: flex;
      overflow-x: auto;
      &::-webkit-scrollbar{
        display: none;
      }
      button{
        border-bottom: 2px solid #2b2b2b6f;
        border-left: none;
      }
      .active{
        border-bottom: 4px solid #263ed9;
        border-left: none;
      }
    }
    .content{
      padding-left:0px;
        width: 100%;
      .items{
        grid-template-columns: repeat(3,1fr);
        column-gap: 5px;
          span,a{
            font-size: 16px;
            img{
              padding-right: 5px;
            }
          }
      }
    }
  }}
  .notif{
    width: 90vw;  
    left: 1em;
  }
}
