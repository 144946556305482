@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;700&family=Rubik:wght@400;500&display=swap");
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: "Rubik", sans-serif;
  color: #2b2b2b;
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@-webkit-keyframes upOp {
  from {
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%);
    opacity: 0.2;
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

@keyframes upOp {
  from {
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%);
    opacity: 0.2;
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes show {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}

@keyframes show {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}

.loading {
  background-color: #e0e0db;
  height: 100vh;
  width: 100%;
}

.loading .center {
  top: 50%;
  left: 50%;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.loading .center img {
  width: 200px;
  height: auto;
  padding-bottom: 5px;
  -webkit-animation: show 1s linear infinite alternate;
          animation: show 1s linear infinite alternate;
}

.dflex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.container {
  max-width: 70%;
  margin-left: auto !important;
  margin-right: auto !important;
}

.firstback {
  background-color: #e0e0db;
  height: 100vh;
  width: 100%;
  background-image: url("../images/backhero.svg");
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 75%;
}

nav {
  -webkit-animation: fadeIn 0.5s ease-out;
          animation: fadeIn 0.5s ease-out;
}

nav .backContainer {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  width: 100%;
  height: 100px;
  padding: 0 5%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
}

nav .backContainer .menu {
  font-size: 16px;
}

nav .backContainer .menu a {
  padding-right: 50px;
  font-size: 14px;
  letter-spacing: 1px;
  color: #2b2b2b;
  text-transform: uppercase;
}

nav .backContainer .menu a:hover {
  color: #263ed9;
}

nav .backContainer .menu .resumebtn {
  border: 1px solid #263ed9;
  padding: 10px 25px;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 500;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: #263ed9;
  border: solid 2px #263ed9;
  color: white;
}

nav .backContainer .menu .resumebtn:hover {
  background-color: #e0e0db;
  color: #263ed9;
}

nav .humicon {
  display: none;
}

.logo {
  padding-left: 30px;
}

.logo img {
  width: 120px;
}

.scrollTrue {
  height: 70px !important;
  background-color: rgba(224, 224, 219, 0.85);
  -webkit-transform: translateY(0%) !important;
          transform: translateY(0%) !important;
}

.scrollFalse {
  -webkit-transform: translateY(-100%) !important;
          transform: translateY(-100%) !important;
}

.header {
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-animation: upOp 1s;
          animation: upOp 1s;
}

.header .content p:first-child {
  color: #263ed9;
  font-size: 20px;
}

.header .content h1 {
  margin-top: 20px;
  font-size: 70px;
  margin-bottom: 10px;
}

.header .content h2 {
  font-size: 55px;
  color: #3f3f3f;
}

.header .content p {
  font-size: 20px;
  width: 73%;
  margin-top: 20px;
  line-height: 30px;
}

.header .content button {
  font-size: 16px;
  margin-top: 50px;
  padding: 15px 30px;
  background-color: #263ed9;
  border: solid 2px #263ed9;
  color: white;
  border-radius: 5px;
}

.header .content button:hover {
  background-color: #e0e0db;
  color: #263ed9;
}

.Social .contactbtnFix {
  position: fixed;
  z-index: 20;
  right: 3%;
  bottom: 5%;
}

.Social .contactbtnFix:hover {
  bottom: calc(5% + 3px);
}

.Social .contactbtnFix button {
  -webkit-animation: upOp 1s;
          animation: upOp 1s;
  border: transparent;
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  align-items: center;
  background: linear-gradient(130deg, #263ed9, #00ccff);
  -webkit-box-shadow: rgba(0, 77, 255, 0.5) 0px 4px 24px;
          box-shadow: rgba(0, 77, 255, 0.5) 0px 4px 24px;
  width: 60px;
  height: 60px;
}

.Social .contactbtnFix button img {
  width: 35px;
  height: auto;
}

.Social .contactbtnFix button:hover {
  bottom: 6%;
  background: linear-gradient(130deg, #263ed9, #51dcff);
}

.about {
  padding: 100px 0 0 0;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1.5fr;
      grid-template-columns: 1fr 1.5fr;
  max-width: 85%;
}

.about .leftSide {
  position: relative;
}

.about .leftSide h4 {
  font-size: 35px;
  line-height: 57px;
  margin-bottom: 55px;
}

.about .leftSide h4 img {
  margin-bottom: -10px;
}

.about .leftSide h4 span {
  display: block;
}

.about .leftSide h4 span:nth-of-type(2) {
  margin-left: 10%;
}

.about .leftSide h4 span:nth-of-type(3) {
  margin-left: 30%;
}

.about .leftSide p {
  font-size: 14px;
  color: #263ed9;
  margin: 10px 0;
  letter-spacing: 1px;
  margin-left: 5px;
}

.about .leftSide .iconSocail {
  position: absolute;
  bottom: 0;
  width: 70%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.about .leftSide .iconSocail a {
  margin: 0 10px;
  padding: 13px;
}

.about .leftSide .iconSocail a img {
  width: 30px;
  -webkit-filter: invert(15%) sepia(3%) saturate(24%) hue-rotate(354deg) brightness(70%) contrast(94%);
          filter: invert(15%) sepia(3%) saturate(24%) hue-rotate(354deg) brightness(70%) contrast(94%);
}

.about .leftSide .iconSocail a:hover {
  margin-top: -5px;
  -webkit-filter: invert(20%) sepia(65%) saturate(4256%) hue-rotate(230deg) brightness(89%) contrast(91%);
          filter: invert(20%) sepia(65%) saturate(4256%) hue-rotate(230deg) brightness(89%) contrast(91%);
}

.about .rigthSide {
  padding-top: 50px;
}

.about .rigthSide p {
  font-size: 18px;
  line-height: 28px;
}

.about .rigthSide .focus {
  margin: 16px 0 24px 0;
  font-weight: 700;
  color: #2b2b2b;
}

.about .rigthSide .rigthBoxs {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[3];
      grid-template-columns: repeat(3, 1fr);
}

.about .rigthSide .rigthBoxs span {
  color: #263ed9;
  font-size: 14px;
  text-transform: uppercase;
}

.about .rigthSide .rigthBoxs p {
  font-size: 18px;
  font-weight: 500;
  margin-top: 3px;
}

.sectionTitle {
  font-size: 22px;
  text-transform: uppercase;
  color: #3f3f3f;
  font-weight: 500;
  letter-spacing: 1px;
  padding-bottom: 25px;
  position: relative;
}

.sectionTitle::after {
  content: "";
  width: 50px;
  height: 4px;
  background-color: #263ed9;
  position: absolute;
  bottom: 5px;
  left: 0px;
}

.Projects {
  padding-top: 150px;
}

.Projects .ProContainer {
  max-width: 85%;
  margin: auto;
}

.Projects .ProContainer h3 {
  margin-top: 0px;
  margin-bottom: 25px;
}

.Project {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[12];
      grid-template-columns: repeat(12, 1fr);
  gap: 10px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 100px;
}

.Project:last-of-type {
  margin-bottom: 0px;
}

.Project:nth-of-type(even) .ProDetails {
  grid-column: 7/-1;
  grid-area: 1/7/-1/-1;
  text-align: right;
}

.Project:nth-of-type(even) .ProjDemo {
  -ms-grid-column: 1;
  -ms-grid-column-span: 7;
  grid-column: 1/8;
  -ms-grid-column: 1;
  -ms-grid-column-span: 7;
  grid-area: 1/1/-1/8;
}

.Project .ProDetails {
  -ms-grid-column: 1;
  -ms-grid-column-span: 6;
  grid-column: 1/7;
  -ms-grid-column: 1;
  -ms-grid-column-span: 6;
  grid-area: 1/1/-1/7;
  z-index: 10;
}

.Project .ProDetails h4 {
  font-size: 24px;
  color: #2b2b2b;
  font-weight: 500;
  padding: 15px 0;
  font-weight: 700;
}

.Project .ProDetails .description {
  font-size: 18px;
  line-height: 28px;
  padding: 20px;
  background-color: #e0e0db;
  margin: 20px 0;
  border-radius: 5px;
  font-weight: 500;
  color: #2b2b2b;
}

.Project .ProDetails .tags {
  font-size: 16px;
  color: #3f3f3f;
  font-weight: 500;
  word-spacing: 10px;
  margin-bottom: 25px;
  line-height: 26px;
  font-weight: 700;
}

.Project .ProDetails a:first-of-type {
  padding-right: 20px;
}

.Project .ProDetails a:hover {
  -webkit-filter: invert(20%) sepia(65%) saturate(4256%) hue-rotate(230deg) brightness(89%) contrast(91%);
          filter: invert(20%) sepia(65%) saturate(4256%) hue-rotate(230deg) brightness(89%) contrast(91%);
}

.Project .ProjDemo {
  grid-column: 6/-1;
  grid-area: 1/6/-1/-1;
  position: relative;
}

.Project .ProjDemo:hover .linkIcon {
  display: block;
}

.Project .ProjDemo:hover .imageLink img {
  -webkit-filter: grayscale(100%) contrast(1) brightness(90%);
          filter: grayscale(100%) contrast(1) brightness(90%);
}

.Project .ProjDemo .imageLink img {
  width: 100%;
}

.Project .ProjDemo .linkIcon {
  display: none;
}

.Project .ProjDemo .linkIcon img {
  width: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(3deg) brightness(100%) contrast(104%);
          filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(3deg) brightness(100%) contrast(104%);
}

.contact {
  padding-top: 200px;
  padding-bottom: 100px;
  max-width: 85%;
}

.contact h3 {
  margin-bottom: 25px;
}

.contact .conatactTypes {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 0.5fr;
      grid-template-columns: 1fr 0.5fr;
}

.contact .conatactTypes h4 {
  font-size: 40px;
  color: #3f3f3f;
  margin-bottom: 10px;
}

.contact .conatactTypes .form {
  margin-top: 50px;
  border-right: 2px solid #3f3f3f3d;
}

.contact .conatactTypes .form input {
  padding: 25px 0;
  margin-bottom: 30px;
  border: none;
  border-bottom: 2px solid #3f3f3f94;
  font-size: 18px;
  outline: none;
  color: #2b2b2b;
}

.contact .conatactTypes .form .name {
  width: 42.5%;
  margin-right: 5%;
}

.contact .conatactTypes .form .email {
  width: 42.5%;
}

.contact .conatactTypes .form .subject {
  display: block;
  width: 90%;
}

.contact .conatactTypes .form .message {
  width: 90%;
  border: none;
  border-bottom: 1px solid #2b2b2b;
  font-size: 18px;
  outline: none;
  padding-bottom: 80px;
  margin-bottom: 35px;
  resize: vertical;
}

.contact .conatactTypes .form .erorr {
  border-bottom: 2px solid #c94451 !important;
}

.contact .conatactTypes .form button {
  font-size: 16px;
  padding: 15px 30px;
  background-color: #263ed9;
  border: solid 2px #263ed9;
  color: white;
  border-radius: 5px;
}

.contact .conatactTypes .form button:hover {
  background-color: white;
  color: #263ed9;
}

.contact .otherContact {
  width: 90%;
  margin: auto;
  padding-left: 20px;
  padding-top: 100px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.contact .otherContact h5 {
  font-size: 25px;
  margin-bottom: 30px;
}

.contact .otherContact p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #2b2b2b;
  font-weight: 500;
  margin-bottom: 30px;
}

.contact .otherContact p:last-of-type {
  letter-spacing: 2px;
}

.contact .otherContact p img {
  margin-right: 15px;
}

.contact .otherContact .mediatitle {
  margin-bottom: 25px;
}

.contact .otherContact .socilaMedia {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  text-align: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.contact .otherContact .socilaMedia img {
  width: 30px;
  -webkit-filter: invert(15%) sepia(3%) saturate(24%) hue-rotate(354deg) brightness(70%) contrast(94%);
          filter: invert(15%) sepia(3%) saturate(24%) hue-rotate(354deg) brightness(70%) contrast(94%);
}

.contact .otherContact .socilaMedia a:hover {
  margin-top: -3px;
  -webkit-filter: invert(20%) sepia(65%) saturate(4256%) hue-rotate(230deg) brightness(89%) contrast(91%);
          filter: invert(20%) sepia(65%) saturate(4256%) hue-rotate(230deg) brightness(89%) contrast(91%);
}

footer {
  background-color: #e0e0db;
  margin-top: 100px;
  padding: 30px 9%;
  padding-bottom: 15px;
}

footer h4 {
  text-align: center;
  margin-top: 5px;
  font-size: 14px;
  font-weight: 500;
}

footer .footerbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  justify-items: center;
}

footer .footerbar h1 {
  font-size: 25px;
}

footer .footerbar .iconSocail a {
  margin: 0;
  padding: 15px;
}

footer .footerbar .iconSocail a img {
  width: 23px;
  -webkit-filter: invert(15%) sepia(3%) saturate(24%) hue-rotate(354deg) brightness(70%) contrast(94%);
          filter: invert(15%) sepia(3%) saturate(24%) hue-rotate(354deg) brightness(70%) contrast(94%);
}

footer .footerbar .iconSocail a:hover {
  margin-top: -5px;
  -webkit-filter: invert(20%) sepia(65%) saturate(4256%) hue-rotate(230deg) brightness(89%) contrast(91%);
          filter: invert(20%) sepia(65%) saturate(4256%) hue-rotate(230deg) brightness(89%) contrast(91%);
}

.skills {
  max-width: 85%;
  margin: auto;
  padding-top: 150px;
}

.skills .slider {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 35px;
  max-width: 80%;
  margin: auto;
}

.skills .slider .sliderControl button {
  display: block;
  width: 180px;
  padding: 25px;
  border: none;
  font-size: 16px;
  text-align: left;
  background: none;
  font-weight: 500;
  border-left: 3px solid #2b2b2b6f;
  letter-spacing: 1px;
  outline: none;
}

.skills .slider .sliderControl button:hover {
  background-color: #e0e0db;
  color: #263ed9;
}

.skills .slider .sliderControl .active {
  border-left: 4px solid #263ed9;
  color: #263ed9;
  font-weight: 700;
  background-color: #e0e0db;
}

.skills .slider .content {
  padding-left: 50px;
  width: 75%;
}

.skills .slider .content h4 {
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 20px 0;
  color: #2b2b2b;
}

.skills .slider .content h4:last-of-type {
  margin-top: 35px;
}

.skills .slider .content .items, .skills .slider .content .items2 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[4];
      grid-template-columns: repeat(4, 1fr);
  -webkit-column-gap: 20px;
          column-gap: 20px;
  row-gap: 15px;
}

.skills .slider .content .items span, .skills .slider .content .items2 span {
  text-align: center;
}

.skills .slider .content .items span, .skills .slider .content .items2 span, .skills .slider .content .items a, .skills .slider .content .items2 a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 18px;
  padding: 5px 0;
}

.skills .slider .content .items span img, .skills .slider .content .items2 span img, .skills .slider .content .items a img, .skills .slider .content .items2 a img {
  padding-right: 10px;
}

.skills .slider .content .items a:hover, .skills .slider .content .items2 a:hover {
  background-color: #e0e0db;
  border-radius: 5px;
}

.skills .slider .content .items2 {
  -ms-grid-columns: (1fr)[3];
      grid-template-columns: repeat(3, 1fr);
  row-gap: 5px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.skills .slider .content .items2 a {
  font-size: 16px;
}

@-webkit-keyframes send {
  from {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  to {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
  }
}

@keyframes send {
  from {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  to {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
  }
}

@-webkit-keyframes shownotif {
  from {
    -webkit-transform: translateX(200%);
            transform: translateX(200%);
  }
  to {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}

@keyframes shownotif {
  from {
    -webkit-transform: translateX(200%);
            transform: translateX(200%);
  }
  to {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}

.notif {
  -webkit-animation: shownotif 1s;
          animation: shownotif 1s;
  position: fixed;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
  z-index: 100;
  font-weight: 500;
  font-size: 16px;
  right: 1em;
  top: 1em;
  background-color: white;
  width: 250px;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
          box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
}

.notif p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 15px 10px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.notif p img {
  margin-right: 10px;
}

.notif .barnoti {
  width: 100%;
  height: 5px;
  background-color: #07bc0c;
  -webkit-transform-origin: left;
          transform-origin: left;
  -webkit-animation: send 5s;
          animation: send 5s;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
  border-radius: 0 4px 4px 0;
}

@media (max-width: 768px) {
  .container {
    width: initial;
    max-width: initial;
  }
  html {
    overflow-x: hidden;
  }
  @-webkit-keyframes menueffect {
    from {
      height: 0;
    }
    to {
      height: 100vh;
    }
  }
  @keyframes menueffect {
    from {
      height: 0;
    }
    to {
      height: 100vh;
    }
  }
  .loading .center img {
    width: 150px;
  }
  .firstback {
    background-size: 130% !important;
  }
  .dflex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    padding: 0 25px 35px 25px;
    z-index: 0;
  }
  .backmeu {
    max-width: 100% !important;
    background-color: #e0e0db;
    opacity: 0.96;
    height: 100vh;
    position: fixed;
    width: 100vw;
    left: 0;
    top: 0;
    margin: 0 !important;
    z-index: 10;
    -webkit-animation: menueffect 0.6s;
            animation: menueffect 0.6s;
    scroll-behavior: smooth;
  }
  .scrolN {
    overflow: hidden;
    height: 100vh;
  }
  nav {
    -webkit-animation: none !important;
            animation: none !important;
  }
  nav .backContainer {
    display: block !important;
    padding: 30px 0 !important;
  }
  nav .backContainer .logo {
    padding-left: 0;
  }
  nav .backContainer .logo img {
    height: 35px;
  }
  nav .humicon {
    display: block !important;
    width: 20px;
    cursor: pointer;
  }
  nav .humicon img {
    -webkit-filter: brightness(0);
            filter: brightness(0);
  }
  nav .menu {
    display: -ms-grid;
    display: grid;
    text-align: center;
    padding: 0 25px;
  }
  nav .menu a {
    color: white;
    font-size: 18px;
    padding: 25px 0 !important;
    letter-spacing: 4px;
    border-top: 1px solid black;
  }
  nav .menu a:nth-last-child(2) {
    border-bottom: 1px solid black;
  }
  nav .menu .resumebtn {
    margin-top: 30px;
    padding: 15px 0 !important;
    font-size: 18px !important;
  }
  nav .dismenu {
    display: none;
  }
  .space {
    margin-top: 0vh !important;
  }
  .header {
    max-width: initial;
    padding: 0 9%;
  }
  .header .content h1 {
    margin-top: 15px !important;
    font-size: clamp(35px, 7vw, 80px) !important;
  }
  .header .content h2 {
    font-size: clamp(30px, 7vw, 60px) !important;
  }
  .header .content p {
    font-size: 16px !important;
    width: 100% !important;
    line-height: 20px;
  }
  .sectionTitle {
    font-size: 18px;
  }
  .Social .contactbtnFix button {
    width: 55px !important;
    height: 55px !important;
  }
  .Social .contactbtnFix button img {
    width: 25px !important;
  }
  .about {
    padding: 0 9%;
    margin-top: 75px;
    display: block !important;
  }
  .about .leftSide h4 {
    display: none !important;
  }
  .about .leftSide .iconSocail {
    display: none !important;
  }
  .about .rigthSide {
    margin-top: 25px;
    padding-top: 0;
  }
  .about .rigthSide .rigthBoxs {
    display: block !important;
  }
  .about .rigthSide .rigthBoxs span {
    font-size: 16px !important;
  }
  .about .rigthSide .rigthBoxs p {
    font-size: clamp(14px, 5vw, 20px);
    margin-top: 10px !important;
    margin-bottom: 20px !important;
  }
  .Projects {
    padding-top: 75px;
  }
  .Project {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    margin-bottom: 50px !important;
    background-color: #e0e0db;
    border-radius: 10px;
    position: relative;
  }
  .Project .ProDetails {
    text-align: left !important;
    padding: 0 25px 25px 25px;
  }
  .Project .ProDetails h4 {
    font-size: clamp(24px, 5vw, 28px) !important;
    padding-bottom: 0;
  }
  .Project .ProDetails .description {
    padding: 0px;
    background: none;
    border-radius: 0px;
    font-size: 14px;
    line-height: 24px;
  }
  .Project .ProDetails .tags {
    font-size: 14px;
  }
  .Project .ProDetails a {
    padding-right: 30px;
  }
  .contact {
    max-width: 90%;
    margin: auto;
    padding-top: 75px;
  }
  .contact .conatactTypes {
    display: initial;
  }
  .contact .conatactTypes h4 {
    font-size: clamp(20%, 7vw, 45px);
  }
  .contact .conatactTypes .form {
    border-right: none !important;
  }
  .contact .conatactTypes .form .name {
    width: 45%;
    margin-right: 10%;
  }
  .contact .conatactTypes .form .email {
    width: 45%;
  }
  .contact .conatactTypes .form .subject {
    width: 100%;
  }
  .contact .conatactTypes .form .message {
    width: 100%;
  }
  .contact .conatactTypes .otherContact {
    width: 100%;
    padding: 50px 0;
  }
  .contact .conatactTypes .otherContact p {
    font-size: clamp(14px, 5vw, 20px);
    margin-right: 5px;
  }
  .contact .conatactTypes .otherContact p img {
    margin-right: 10px;
  }
  .contact .conatactTypes .otherContact .socilaMedia {
    display: none;
  }
  .contact .conatactTypes .otherContact .mediatitle {
    display: none;
  }
  footer {
    margin-top: 0px;
    text-align: center;
  }
  footer .footerbar {
    display: initial;
  }
  footer .footerbar .iconSocail {
    margin-top: 20px;
  }
  footer .footerbar .iconSocail a {
    margin: 0;
    padding: 20px;
  }
  footer h4 {
    font-size: 16px;
  }
  .skills {
    padding-top: 75px;
  }
  .skills .slider {
    max-width: 100%;
    display: block;
    padding-top: 0;
  }
  .skills .slider .sliderControl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow-x: auto;
  }
  .skills .slider .sliderControl::-webkit-scrollbar {
    display: none;
  }
  .skills .slider .sliderControl button {
    border-bottom: 2px solid #2b2b2b6f;
    border-left: none;
  }
  .skills .slider .sliderControl .active {
    border-bottom: 4px solid #263ed9;
    border-left: none;
  }
  .skills .slider .content {
    padding-left: 0px;
    width: 100%;
  }
  .skills .slider .content .items, .skills .slider .content .items2 {
    -ms-grid-columns: (1fr)[3];
        grid-template-columns: repeat(3, 1fr);
    -webkit-column-gap: 5px;
            column-gap: 5px;
  }
  .skills .slider .content .items span, .skills .slider .content .items2 span, .skills .slider .content .items a, .skills .slider .content .items2 a {
    font-size: 16px;
  }
  .skills .slider .content .items span img, .skills .slider .content .items2 span img, .skills .slider .content .items a img, .skills .slider .content .items2 a img {
    padding-right: 5px;
  }
  .notif {
    width: 90vw;
    left: 1em;
  }
}

@media (max-width: 1024px) and (min-width: 769px) {
  .container {
    max-width: 85%;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  nav .menu a {
    padding-right: 30px !important;
  }
  .header {
    max-width: 80% !important;
  }
  .header .content h1 {
    font-size: 60px !important;
  }
  .header .content h2 {
    font-size: 40px !important;
  }
  .header .content p {
    font-size: 18px !important;
    width: 85% !important;
  }
  .about {
    display: block !important;
  }
  .about .leftSide h4 {
    display: none !important;
  }
  .about .leftSide .iconSocail {
    display: none !important;
  }
  .about .rigthSide {
    margin-top: 25px;
    padding-top: 0;
    margin-bottom: 75px;
  }
  .Projects {
    padding: 0;
  }
  .Project {
    margin-bottom: 10%;
  }
  .Project:nth-of-type(even) .ProDetails {
    grid-column: 6/-1;
    grid-area: 1/6/-1/-1;
  }
  .Project:nth-of-type(even) .ProDetails h4 {
    margin-left: auto;
  }
  .Project:nth-of-type(even) .ProDetails .tags {
    width: 70%;
    margin-left: auto;
  }
  .Project .ProjDemo {
    grid-column: 6/-1;
    grid-area: 1/6/-1/-1;
    margin-top: -11%;
  }
  .Project .ProDetails {
    -ms-grid-column: 1;
    -ms-grid-column-span: 7;
    grid-column: 1/8;
    -ms-grid-column: 1;
    -ms-grid-column-span: 7;
    grid-area: 1/1/-1/8;
  }
  .Project .ProDetails h4 {
    width: 70%;
    padding-bottom: 0;
  }
  .Project .ProDetails .description {
    line-height: 1.3;
    font-size: 16px;
  }
  .Project .ProDetails .tags {
    width: 70%;
    margin-bottom: 15px;
  }
  .conatactTypes {
    -ms-grid-columns: 1fr 0.2fr;
        grid-template-columns: 1fr 0.2fr;
  }
  .conatactTypes .directContact .title {
    position: absolute;
  }
  .conatactTypes .directContact .form {
    margin-top: 120px !important;
  }
  .skills .slider {
    max-width: 100%;
  }
}

@media (min-width: 1025px) and (max-width: 1260px) {
  .header {
    max-width: 70% !important;
  }
  .header .content h1 {
    font-size: 65px !important;
  }
  .header .content h2 {
    font-size: 48px !important;
  }
  .header .content p {
    width: 75% !important;
  }
  .about .leftSide h4 {
    line-height: 70px !important;
  }
}
